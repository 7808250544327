<template>
  <v-container fluid>
    <pre><code>{{ 'loco' }}</code></pre>

    <pre><code>{{ $ability }}</code></pre>
  </v-container>
</template>

<script>
export default {
  name: 'Info',

  computed: {
    routes() {
      return this.$router.getRoutes().map(({ components, path, name, meta }) => {
        return { components, path, name, meta };
      });
    },
  },
};
</script>
